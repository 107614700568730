import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { useReveal } from '../hooks/useReveal';

const Markdown = ({ children, pageContext }) => {
  useReveal();
  const { frontmatter } = pageContext;
  return (
    <>
      <SEO title={frontmatter.title} />
      <Layout>
        <div className="container mx-auto py-10 md:py-20 px-4 md:w-10/12 lg:w-8/12 xl:w-6/12 reveal fade-bottom">
          <h5 className="font-display text-5xl md:text-6xl lg:text-10xl">
            {frontmatter.title}
          </h5>

          <div className="mt-8 md:mt-16">
            <MDXProvider
              components={{
                h2: (props) => <h2 {...props} className="mt-8 mb-4 text-3xl" />,
                p: (props) => <p {...props} className="opacity-70 text-lg" />,
                a: (props) => (
                  <a {...props} className="underline underline-offset-4" />
                ),
                li: (props) => (
                  <li
                    {...props}
                    className="ml-4 opacity-70 list-disc text-lg"
                  />
                ),
                hr: (props) => <hr {...props} className="mt-8 mb-4" />,
              }}
            >
              {children}
            </MDXProvider>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Markdown;

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        slug
        title
      }
    }
  }
`;
