import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/dfua22/dfua22/src/templates/Markdown.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The goal of `}<a parentName="p" {...{
        "href": "https://devfest.gdg.org.ua/"
      }}>{`#DevFestForUkraine`}</a>{` is to raise funds that can be used immediately and in the most efficient way to help Ukraine.`}</p>
    <br />
    <p>{`Our criteria for selecting a charity organization were:`}</p>
    <ul>
      <li parentName="ul">{`non-governmental organization`}</li>
      <li parentName="ul">{`reliable volunteering organization with a proven track of records`}</li>
      <li parentName="ul">{`is either providing humanitarian aid to people who suffered from the war (refugees, children) or tools to save lives of warriors that defend Ukraine`}</li>
    </ul>
    <br />
    <p>{`We have diligently reviewed more than 50 organizations and selected 3 out of 50 that we trust ourselves the most: `}<a parentName="p" {...{
        "href": "https://www.comebackalive.in.ua/"
      }}>{`Come Back Alive`}</a>{`, `}<a parentName="p" {...{
        "href": "https://novaukraine.org/"
      }}>{`Nova Ukraine`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://voices.org.ua/en/"
      }}>{`Voice of Children`}</a>{`.`}</p>
    <h2>{`Come Back Alive`}</h2>
    <p>{`Was founded in 2014 after the war started on Donbas and since then became the biggest and the most well-known organization providing support to the Armed Forces of Ukraine. Importantly, Come Back Alive does not use funds for purchasing arms. Their mission is limited to supplying technology, training, and accouterments to help save the lives of Ukrainians and help the warriors defend Ukraine.`}</p>
    <br />
    <p>{`In addition, they run in full transparency and have `}<a parentName="p" {...{
        "href": "https://report.comebackalive.in.ua/public/dashboard/e4f44bc7-05f4-459b-a10b-cc10e6637217?date=past30days~"
      }}>{`open financial reporting`}</a>{`.`}</p>
    <h2>{`Nova Ukraine`}</h2>
    <p>{`Similar to Come Back Alive, Nova Ukraine was founded as a non-profit organization in 2014 in the USA, by Ukrainians. Their mission is to provide humanitarian aid to vulnerable groups and individuals in Ukraine and to raise awareness about Ukraine in the US and throughout the world. For the past years, they have launched `}<a parentName="p" {...{
        "href": "https://novaukraine.org/all-projects/"
      }}>{`numerous projects`}</a>{` in supporting Ukraine and since February 24 they have been one of the biggest supporters of refugees and distributors of humanitarian aid.`}</p>
    <h2>{`Voice of Children`}</h2>
    <p>{`Millions of children have become victims as a result of russia’s full-scale invasion of Ukraine on February 24: 225 children died, 413 were injured, thousands became an orphan, and 2 million went abroad with their parents.`}</p>
    <br />
    <p><a parentName="p" {...{
        "href": "https://voices.org.ua/en/who-we-are/"
      }}>{`The Voices of Children Foundation`}</a>{` has been helping children affected by the war since 2015. Their mission is “`}<em parentName="p">{`None of a child may be left alone with a war trauma`}</em>{`”. Today they are providing non-stop assistance to affected children and families from all over the country, providing emergency psychological assistance, and assisting in the evacuation process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      